body {
  font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased; }

h1 {
  font-weight: 100; }

h4.media-heading {
  font-weight: 600; }

.navbar {
  position: static;
  margin-bottom: 0;
  border-radius: 0;
  border: none;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  z-index: 1; }

.navbar-default .navbar-havucene {
  color: #4cae4c;
  /* #25a55b;*/
  font-weight: 600; }

.navbar-brand .navbar-beta {
  pointer-events: none;
  color: #aaa; }

/* Copy 768 nav bar styles so the signup/login links show up even on small screens */
.navbar-header {
  float: left; }

.navbar-nav {
  float: left;
  margin: 0; }

.navbar-nav > li {
  float: left; }

.navbar-nav > li > a {
  padding-top: 15px;
  padding-bottom: 15px; }

.navbar-nav.navbar-right:last-child {
  margin-right: -15px; }

.navbar-left {
  float: left !important; }

.navbar-right {
  float: right !important; }

.login-btn {
  margin-left: 25px; }

.filter-btn .fa.fa-gamepad, .type-btn .fa.fa-gamepad {
  margin-left: -1px; }

.icon-globe {
  font-size: 17px;
  margin-left: -4px;
  margin-right: -5px; }

.glyphicon-globe {
  margin-top: 2px; }

.dropdown-menu {
  width: 290px; }

.dropdown-menu > li > a {
  line-height: 2.2; }

.dropdown-menu > li.wrap-menu-item > a {
  display: inline-block;
  width: 86px;
  float: left;
  clear: none;
  padding: 3px 5px;
  overflow: hidden; }

.dropdown-menu > li.wrap-menu-item:nth-of-type(3n) > a {
  margin-left: 15px; }

.dropdown-menu > .divider {
  clear: both; }

.one-line {
  white-space: nowrap;
  overflow: hidden; }

.dropdown-menu > li > a > .caret {
  opacity: 0; }

.dropdown-menu > li.menu-item-active > a > .caret {
  opacity: 1; }

/*
.dropdown-menu > li.menu-item-active > a {
  background: #e5e5e5;
  color: white;
}
*/
.dropdown-menu > li.menu-item-active > a > .menu-text {
  position: relative;
  color: white; }

.dropdown-menu > li.menu-item-active > a > .menu-text > * {
  position: relative;
  z-index: 1; }

.dropdown-menu > li.menu-item-active > a > .menu-text:before {
  content: " ";
  background: #3276b1;
  left: -5px;
  top: -5px;
  bottom: -5px;
  right: -5px;
  position: absolute; }

.space-before {
  margin-left: 5px; }

.dropdown-menu .close {
  padding: 0 10px; }

footer.link-footer {
  padding-bottom: 40px;
  color: #777;
  text-align: center; }

.footer-hr {
  margin-top: 40px;
  margin-left: 0;
  margin-right: 0; }

@media (max-width: 767px) {
  .list-header {
    top: 0;
    border-bottom: 1px solid #e7e7e7;
    background: #f8f8f8; } }
@media (min-width: 768px) {
  .list-header {
    top: 51px;
    /* top bar is 50px + 1px bottom margin*/ }

  footer.link-footer {
    margin-top: 50px; } }
@media (max-width: 767px) {
  html:not(.page-scroll-xs), body, .root {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden; }

  html.page-scroll-xs .scroller {
    overflow-y: visible; } }
@media (min-width: 768px) {
  html, body, .root {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden; } }
/* The root element of the page (usually body but may be a form) */
.root {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap; }

/* Set the negative flex amount to 0 so these components stay fixed height even
 if main scrolls */
.root > * {
  flex: 0 0 auto; }

.main {
  position: relative;
  flex: 1 1 auto; }

.scroller {
  position: relative;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  /* set display to flex to get content to fill */
  display: flex;
  flex-direction: column; }

.scroller-content {
  /* make the content 1px bigger than the parent so the parent has to scroll */
  margin-bottom: -1px;
  flex: 1 0 auto; }

.link-buttons > a {
  min-width: 4em; }

html.logged-in .hidden-logged-in {
  display: none; }

html.logged-out .hidden-logged-out {
  display: none; }
